import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

class Help extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Help" />
        <h1>Help</h1>
        <Link to="/">Home</Link>
      </Layout>
    );
  }
}

export default Help;
